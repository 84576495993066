import dynamic from "next/dynamic";

import useHashScroll from "../hooks/useHashScroll";
import usePageRedirect from "../hooks/usePageRedirect";
import {
  getComponentForMagnoliaTemplate,
  magnoliaEditablePageConfig,
} from "../magnolia.config";
import getStaticPaths from "../service/static-paths-api";
import buildGetStaticProps from "../service/static-props-api";
import { WifStaticProps } from "../types/wif-next";

const EditablePage = dynamic(
  () => import("../components/MgnlEditor/EditablePage")
);

export { getStaticPaths };
export const getStaticProps = buildGetStaticProps();

export default function Path(props: WifStaticProps) {
  const { page, templateAnnotations, isPreview } = props;
  const redirectPage = page?.redirectPage ?? null;
  const redirectAnchorTag = page?.redirectAnchorTag ?? null;

  usePageRedirect(redirectPage, redirectAnchorTag);
  useHashScroll(!!redirectPage);

  if (!page) {
    return null;
  }

  if (isPreview) {
    return (
      <EditablePage
        content={page}
        config={magnoliaEditablePageConfig}
        templateAnnotations={templateAnnotations}
      />
    );
  }

  return <CustomPageRenderer {...page} />;
}

function CustomPageRenderer({ main, ...page }: Wif.Mgnl.Page) {
  const PageComponent = getComponentForMagnoliaTemplate(page["mgnl:template"]);

  if (!PageComponent) return null;

  const pageNodes: Wif.Mgnl.Node[] = (main?.["@nodes"] || [])
    .map((name) => main?.[name])
    .filter(Boolean);

  return (
    <PageComponent key={page["@id"]} {...page}>
      {pageNodes.map((node) => {
        const NodeComponent = getComponentForMagnoliaTemplate(
          node["mgnl:template"]
        );

        if (!NodeComponent) return null;

        return <NodeComponent key={node["@id"]} {...node} />;
      })}
    </PageComponent>
  );
}
